<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <restaurar-bdgd-data-table
          :items="items"
          :loading="loading"
          @rechargeTable="getRestauracoesBdgds"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RestauracoesBdgdsService from '@/services/RestauracoesBdgdsService';
import refreshDataMixins from '@/mixins/refreshDataMixins';

export default {
  mixins: [refreshDataMixins],
  components: {
    RestaurarBdgdDataTable: () =>
      import('@/components/dados/restaurar-bdgd/RestaurarBdgdDataTable')
  },
  data: () => ({
    items: [],
    loading: false
  }),
  created() {
    this.refreshData(this.getRestauracoesBdgds);
  },
  methods: {
    getRestauracoesBdgds() {
      this.loading = true;
      RestauracoesBdgdsService.index()
        .then((responseData) => (this.items = responseData))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
